// Here you can add other styles
@media (min-width: 1400px) {
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 2320px;
  }
}
.top-header.container-fluid {
  background-color: $primary-dark;
}
.simplebar-content {
  .nav-link {
    padding-left: 44px;
  }
  .nav-link.active {
    border-left: 2px solid $secondary-dark;
  }
}

.wrap-sidebar-custom {
  .sidebar-custom {
    height: 100%;
    .nav-item.btn-logout {
      border-top: 1px solid $lightblue2;
      position: absolute;
      bottom: 20px;
      left: 0;
      right: 0;
      background-color: $secondary-dark;
      color: $white;
    }
    .nav-link.active {
      pointer-events: none;
    }
  }
}

.btn.btn-logout.btn-default {
  border-top: 1px solid $lightblue2;
  border-radius: 0 !important;
  // position: absolute;
  // bottom: 20px;
  // left: 0;
  // right: 0;
  font-size: 1rem;
  background-color: $secondary-dark;
  color: $white !important;
  border: none;
  // margin-bottom: 20px;
  box-shadow: none;

  &:hover {
    background-color: $secondary-dark;
    color: $white;
    border: none;
  }
}

.float-right {
  float: right;
}
.table {
  // border: 1px solid $black;
  background-color: $white;
  th {
    background-color: $blue-3;
    color: $white;
    vertical-align: middle;
  }
}
.tbl-pointer {
  td {
    cursor: pointer;
  }
}
.tbl-user {
  border-color: transparent;
  th {
    background-color: $secondary-dark;
    color: $white;
    vertical-align: middle;
  }
  tr {
    height: 60px;
  }
  td {
    font-weight: 700;
    border-bottom: 1px solid $blue-6;
  }
}
.pagination {
  margin-top: 34px;
  .page-item.active.btn {
    background-color: transparent;
    .page-link {
      background-color: $primary-dark;
      border-color: $primary-dark;
    }
  }
}
.utable-pagination {
  .page-item.btn {
    .page-link {
      color: $secondary-dark;
    }
  }
  .page-item.active.btn {
    .page-link {
      background-color: $secondary-dark;
      border-color: $secondary-dark;
      color: $white;
    }
  }
}
.pagination-card {
  background-color: transparent;
  .btn {
    padding: 0;
  }
}

.Editor-header {
  background-color: $primary-dark;
}
.card {
  border-radius: $border-radius2;
  border: none;
  box-shadow: $box-shadow;
}
.faq-page {
  .faq-acc-card {
    background-color: transparent;
    .faq-acc-accordion {
      border-radius: $border-radius2;
      .accordion-item {
        margin-bottom: 10px;
        .btn-faq-publish {
          position: absolute;
          right: 75px;
        }
        .txt-lbl {
          background-color: $accepting;
          color: $white;
          padding: 5px;
          position: absolute;
          right: 0;
          top: 0;
          font-size: 10px;
        }
      }
      .accordion-button.collapsed {
        p {
          // margin-top: 20px;
          margin: auto;
        }
      }
      .accordion-button:not(.collapsed) {
        background-color: $white;
      }
      .accordion-button {
        border: none;
        min-height: 75px;
      }
      .faq-q {
        max-width: 80%;
        font-weight: bold;
      }
    }
  }
  .accordion-body {
    .btn-eding {
      margin-top: 34px;
    }
  }
}
.chip {
  // background-color: $primary-dark;
  border-radius: $border-radius2;
  color: $white;
  padding: 2px;
  &.publish {
    background-color: $primary-success;
  }
  &.draft {
    background-color: $lightblue;
  }
  &.new {
    background-color: #35b2e4;
  }
  &.active {
    background-color: $primary-success;
  }
  &.trialing  {
    background-color: #da5b06;
  }
  &.canceled {
    background-color: #da0611;
  }
}
.txt-nodataavailable {
  border: none;
}
.Page.navigation.example {
  .page-item.active.btn {
    .page-link {
      background-color: $primary-dark;
    }
  }
}
.sidebar-toggler {
  display: none !important;
}

.spinner-border {
  margin: auto;
  display: flex;
  margin-top: 34px;
}
.btn-transparent {
  display: none;
}

.card-active-users {
  background-color: $primary-success;
  color: $white;
}

.card-shippers {
  background-color: $primary-dark;
  color: $white;
}
.card-carriers {
  background-color: $secondary-dark;
  color: $white;
}

.card-com-jobs {
  background-color: $primary-success;
  color: $white;
}

.card-jobs {
  background-color: $blue-3;
  color: $white;
}
.card-loads {
  background-color: $blue-2;
  color: $white;
}
.card-quotes {
  background-color: $lightblue;
  color: $white;
}
.card-newSubscription {
  background-color: $blue-5;
  color: $white;
}
.card-sign-from-email {
  background-color: $email;
  color: $white;
}
.card-sign-from-google {
  background-color: $google;
  color: $white;
}.card-sign-from-fb-ad {
  background-color: $fb-ad;
  color: $white;
}.card-sign-from-fb {
  background-color: $fb;
  color: $white;
}.card-sign-from-insta {
  background-color: $insta;
  color: $white;
}
.breadcrumb {
  font-size: 14px;
  // padding-left: 30px;
  border-radius: 0;
  padding-top: 0px;
  padding-bottom: 0;
  margin-bottom: 12px;
  .breadcrumb-item {
    a {
      color: $black;
    }
  }
}
.breadcrumb-item.active {
  color: $primary-dark;
}

.Editor-header {
  text-align: center;
  background-color: #1340a1;
  min-height: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  text-align: center;
}

.Wrapper-class {
  padding: 1rem 1rem 2rem;
}
.Editor-class {
  background-color: white;
  padding: 1rem;
  border: 1px solid #ccc;
  &.error {
    border: 2px solid rgb(219, 0, 0);
  }
}
.Toolbar-class {
  border: 1px solid #ccc;
}

.Preview {
  padding: 1rem;
  margin-top: 1rem;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.btn-active {
  background-color: $primary-success !important;
  color: $white !important;
  border: 1px solid $primary-success;
}

.btn-deactive {
  background-color: $expired !important;
  color: $white !important;
  border: 1px solid $expired;
}

.trial-period, .load-edit{
.icon{
  margin-left: 10px;
}
}

.trial-period-edit, .load-edit{
  .cancel-btn{
    margin-left: 20px;
  }
  .update-btn{
    margin-left: 10px;
  }
}

.group-btn{
  width: 20%;
  padding-top: 3rem;
  padding-bottom: 1.8rem;
  padding-right: 2rem;
  padding-left: 2rem;
  .plan-name{
    color: $blue-3;
    font-size: 1.8em;
    font-weight: 600;
    padding-bottom: 0.6rem;
  }
  .plan-amount{
    color: $dark-blue;
    font-size: 40px;
    font-weight: 700;
    padding-bottom: 0.5rem;
  }
  .plan-time{
    color: $blue-1;
    font-size: 1.4em;
    font-weight: 600;
    padding-bottom: 2rem;
  }
  .plan-descrip{
    color: $blue-2;
    font-size: 1.1em;
    font-weight: 600;
  }
  &:hover {
    border-color: $secondary-dark;
  }
}

@media (min-width: 1191px) and (max-width: 1300px) {
  .group-btn {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    .plan-name{
      font-size: 1.6em;
    }
  }
}

@media (min-width: 1000px) and (max-width: 1190px) {
   .group-btn {
     padding-right: 1.4rem;
     padding-left: 1.4rem;
     padding-top: 2.5rem;
    .plan-name{
      font-size: 1.3em;
      padding-bottom: 0.4rem;
    }
    .plan-amount{
      font-size: 30px;
      padding-bottom: 0.35rem;
    }
    .plan-time{
      font-size: 1em;
      padding-bottom: 1.5rem;
    }
    .plan-descrip{
      font-size: 0.8em;
    }
   }
}

@media screen and (max-width: 1000px) {
  .group-btn {
    width: 40%;
    padding-right: 0;
    padding-left: 0;
    margin-bottom: 25px;
  }
}
