// Variables
// Color system

//--
$transition: 0.5s;
$border-radius: 1px;
$border-radius2: 5px;
// colors
$section-bg-color: #f5f7fb;
$primary-dark: #1340a1;
$primary-success: #1c902f;
$dark-blue: #133073;
$lightblue: #929fb1;
$lightblue2: #6e7a8a;
$accientblue: #f5f7fb;
$secondary-dark: #ff5829;
$white: #ffffff;
$black: #1f2539;
$footer: #292b32;

//profile
$blue: #244b99;
$blue-1: #6e7a8a; //title-3
$blue-2: #70899d; //tab-title
$blue-3: #404b5a; // discriptions
$blue-4: #e8e8e8; //border inputs
$blue-5: #202833;
$blue-6: #ccd4db;

$green-1: #770f05;
$orange-1: #e63e0e;

$email: #f2a60c;
$google: #c71610;
$fb-ad: #1a626a;
$fb: #3b5998;
$insta: #fb11a9;
//load status
$accepting: #1c902f;
$expired: #e60e0e;
$draft: #1340a1;
$withdrawn: #404b5a;
$finalized: #e63e0e;

$box-shadow: #dde4eecc 0px 2px 8px 0px;
//buttons
@mixin button-set($btn) {
  background: $btn;
  &:hover {
    background: darken($btn, 8%);
    transition: all 0.3s ease;
  }
  &:active {
    background: darken($btn, 25%);
  }
}
.mr-2 {
  margin-right: 1rem;
}
.btn {
  font-size: 1.2em;
  border-radius: 5px !important;
  border: none;
  font-size: 1rem;
  font-weight: 700;
  padding: 0.5rem 1.2rem;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  &.btn-primary {
    @include button-set($white);
    color: $primary-dark;
    &:hover {
      color: $white;
    }
  }
  &.btn-primary-outline {
    border: 1px solid $primary-dark;
    color: $primary-dark;
    &:hover {
      background-color: $lightblue2;
      border-color: $lightblue2;
    }
  }
  &.btn-secondary {
    @include button-set($secondary-dark);
    color: $white;
  }
  &.btn-delete {
    @include button-set($expired);
      border-color: $expired;
      color: $white;
  }
  &.btn-secondary-outline {
    border: 1px solid $secondary-dark;
    color: $secondary-dark;
    &:hover {
      background-color: $lightblue2;
      border-color: $lightblue2;
    }
  }
  &.btn-green-outline {
    border: 1px solid $primary-success;
    color: $primary-success;
    &:hover {
      background-color: $lightblue2;
      border-color: $primary-success;
    }
  }
  &.btn-default {
    @include button-set($primary-dark);
    color: $white;
  }
  &.btn-cancel {
    @include button-set($blue-3);
    color: $white;
  }
  &.btn-grp {
    @include button-set($white);
    color: $black;
    border: 1px solid #ccd4db;
  }
}
// forms
.form-control {
  border-radius: $border-radius2 !important;
  border-color: $blue-4;
  &:disabled {
    background-color: #d5dde5;
  }
}
.form-select {
  border-radius: $border-radius2 !important;
  border-color: $blue-4;
  &:disabled {
    background-color: #d5dde5;
  }
}
// titles

.title-1 {
  font-size: 2rem;
  font-weight: bold;
  line-height: 3.5rem;
  margin-bottom: 1rem;
}

.subtitle-1 {
  font-size: 1.25rem;
  font-weight: 500;
}
.subtitle-3 {
  font-size: 1rem;
  font-weight: 600;
  color: #545a77;
}
